<template>
  <div v-if="loading">
    <SpinnerView />
  </div>
  <div v-else>
    <!-- Breadcrumb -->
    <nav class="breadcrumb is-small" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Orders' }">Orders</router-link>
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ order.supplierName }}</a>
        </li>
      </ul>
    </nav>

    <div class="columns">
      <!-- Order Details Column -->
      <div class="column box mx-3">
        <h2 class="is-size-4">Order Details</h2>
        <div class="mt-5">
          <p><b>Order ID: </b> {{ order.id }}</p>
          <p>
            <b>Supplier Name: </b>
            <router-link
              :to="{ name: 'SupplierView', params: { id: order.supplierId } }"
            >
              {{ order.supplierName }}
            </router-link>
          </p>
          <p v-if="order.locationName">
            <b>Location Name: </b> {{ order.locationName }}
          </p>
          <p>
            <b>Created By: </b> {{ order.createdBy }} via
            {{ order.requestedBy }}
          </p>

          <!-- Volume Details -->
          <div class="mt-5">
            <p>
              <b>Expected Volume: </b>
              {{
                order.estimatedVolume
                  ? order.estimatedVolume + " (ltrs)"
                  : "0 (ltrs)"
              }}
            </p>
            <p>
              <b>Actual Volume: </b>
              {{
                order.actualVolume ? order.actualVolume + " (ltrs)" : "0 (ltrs)"
              }}
            </p>
          </div>

          <!-- Scheduling Details -->
          <div class="mt-5">
            <p>
              <b>Requested For: </b>
              {{ new Date(order.requestedDate).toDateString() }}
            </p>
            <span>
              <b>Planned For: </b>
              <span v-if="editSchedule">
                <select v-model="plannedFor" required>
                  <option
                    v-for="date in dateList"
                    :key="date.timestamp"
                    :value="date.timestamp"
                  >
                    {{ date.date }}
                  </option>
                </select>
              </span>
              <span v-else>
                {{
                  order.scheduledDate
                    ? new Date(order.scheduledDate).toDateString()
                    : "TBC"
                }}
              </span>
            </span>
            <p v-if="order.routeId">
              <b>Route ID: </b>
              <router-link
                :to="{ name: 'RouteView', params: { id: order.routeId } }"
              >
                {{ order.routeId }}
              </router-link>
            </p>
          </div>

          <!-- Sign Off Details -->
          <div v-if="order.status === 'COMPLETED'" class="mt-5">
            <p v-if="order.signedOffBy">
              <b>Signed off by: </b> {{ order.signedOffBy }}
            </p>
            <span v-if="imageData">
              <b>Signature: </b>
              <img
                v-bind:src="'data:image/png;base64,' + imageData"
                alt="Image"
                height="20"
                width="200"
              />
            </span>
          </div>
        </div>

        <!-- Order Timestamps Table -->
        <div class="mt-5">
          <table class="table is-bordered is-fullwidth">
            <thead>
              <tr>
                <th>Requested At</th>
                <th>Accepted At</th>
                <th>Scheduled At</th>
                <th>Collected At</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    order.createdAt
                      ? new Date(order.createdAt).toLocaleString()
                      : "Pending"
                  }}
                </td>
                <td>
                  {{
                    order.plannedAt
                      ? new Date(order.plannedAt).toLocaleString()
                      : "Pending"
                  }}
                </td>
                <td>
                  {{
                    order.scheduledAt
                      ? new Date(order.scheduledAt).toLocaleString()
                      : "Pending"
                  }}
                </td>
                <td>
                  {{
                    order.collectedAt
                      ? new Date(order.collectedAt).toLocaleString()
                      : "Pending"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Status / Edit Column -->
      <div class="column mx-3">
        <!-- Display Order Status -->
        <div class="box" v-if="!editOrder">
          <div
            class="container is-flex is-justify-content-space-between is-align-items-center"
          >
            <h1 class="is-size-4">
              Status:
              <span
                v-if="order.status === 'FAILED'"
                class="tag is-rounded is-danger is-size-5"
              >
                {{ order.status }}
              </span>
              <span
                v-else-if="order.status === 'REQUESTED'"
                class="tag is-rounded is-size-5"
              >
                {{ order.status }}
              </span>
              <span
                v-else-if="order.status === 'PLANNED'"
                class="tag is-rounded is-warning is-size-5"
              >
                {{ order.status }}
              </span>
              <span
                v-else-if="order.status === 'SCHEDULED'"
                class="tag is-rounded is-success is-size-5"
              >
                {{ order.status }}
              </span>
              <span
                v-else-if="order.status === 'COMPLETED'"
                class="tag is-rounded is-dark is-size-5"
              >
                {{ order.status }}
              </span>
            </h1>
            <div>
              <button
                v-if="['PLANNED', 'SCHEDULED', 'FAILED'].includes(order.status)"
                class="button is-rounded"
                @click="editOrder = true"
              >
                <img
                  src="@/assets/icons/pencil.png"
                  alt="Edit"
                  style="height: 20px"
                />
              </button>
            </div>
          </div>
          <div v-if="order.notes"><b>Notes:</b> {{ order.notes }}</div>
        </div>

        <!-- Edit Order Section -->
        <div class="box" v-else>
          <div
            class="container is-flex is-justify-content-space-between is-align-items-center"
          >
            <h1 class="is-size-4">Edit Order</h1>
            <button class="button is-rounded" @click="editOrder = false">
              <img
                src="@/assets/icons/delete.png"
                alt="Cancel Edit"
                style="height: 20px"
              />
            </button>
          </div>
          <div class="container is-flex is-flex-direction-column">
            <form @submit.prevent="updateOrder">
              <div class="field is-flex is-align-items-center">
                <label class="label">Status: </label>
                <div class="select control ml-5">
                  <select v-model="editOrderStatus" required>
                    <option value="SCHEDULED">SCHEDULED</option>
                    <option value="FAILED">FAILED</option>
                    <option value="COMPLETED">COMPLETED</option>
                  </select>
                </div>
              </div>
              <div
                class="field is-flex is-align-items-center"
                v-if="editOrderStatus === 'COMPLETED'"
              >
                <label class="label">Actual Volume: </label>
                <div class="control ml-5">
                  <input
                    class="input"
                    type="number"
                    v-model="editOrderActualVolume"
                    required
                  />
                </div>
              </div>
              <div class="field is-flex is-align-items-center">
                <label class="label">Notes: </label>
                <div class="control ml-5">
                  <textarea
                    class="textarea"
                    v-model="editOrderNotes"
                    required
                    placeholder="Enter update notes"
                  ></textarea>
                </div>
              </div>
              <button type="submit" class="button is-rounded is-info">
                Update
              </button>
            </form>
          </div>
        </div>

        <!-- Location Map -->
        <div class="box">
          <h2 class="is-size-4">Location Map</h2>
          <GoogleMap
            api-key="AIzaSyAhURDudHSVdIaghKlgWaE4BNAw_hdzPSE"
            style="width: 100%; height: 300px"
            :center="center"
            :zoom="15"
          >
            <Marker :options="{ position: center }" />
          </GoogleMap>
        </div>
      </div>
    </div>

    <!-- Past Orders Table -->
    <div class="box mr-3">
      <h2 class="is-size-4">Past Orders</h2>
      <div class="mt-5">
        <Vue3EasyDataTable
          :headers="pastOrderHeaders"
          :items="pastOrders"
          header-text-direction="center"
          body-text-direction="center"
          border-cell
        >
          <template #item-id="{ id }">
            <router-link :to="{ name: 'OrderView', params: { id: id } }">
              {{ id }}
            </router-link>
          </template>
          <template #item-createdAt="{ createdAt }">
            {{ new Date(createdAt).toLocaleString() }}
          </template>
          <template #item-collectedAt="{ collectedAt }">
            {{
              collectedAt ? new Date(collectedAt).toLocaleString() : "Pending"
            }}
          </template>
          <template #item-status="{ status }">
            <span v-if="status === 'FAILED'" class="tag is-rounded is-danger">{{
              status
            }}</span>
            <span v-else-if="status === 'REQUESTED'" class="tag is-rounded">{{
              status
            }}</span>
            <span
              v-else-if="status === 'PLANNED'"
              class="tag is-rounded is-warning"
              >{{ status }}</span
            >
            <span
              v-else-if="status === 'SCHEDULED'"
              class="tag is-rounded is-success"
              >{{ status }}</span
            >
            <span
              v-else-if="status === 'COMPLETED'"
              class="tag is-rounded is-dark"
              >{{ status }}</span
            >
          </template>
        </Vue3EasyDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import { GoogleMap, Marker } from "vue3-google-map";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import axios from "axios";

export default {
  props: ["id"],
  setup(props) {
    const store = useStore();
    const apiUrl = store.state.apiUrl;
    const loading = ref(true);
    const order = ref(null);
    const center = ref({});
    const pastOrders = ref([]);
    const imageData = ref(null);
    const editOrder = ref(false);
    const editOrderStatus = ref(null);
    const editOrderActualVolume = ref(null);
    const editOrderNotes = ref(null);
    const plannedFor = ref(null);

    // Local scheduling date list (next 7 days)
    const dateList = computed(() => {
      const dates = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      for (let i = 0; i < 7; i++) {
        const d = new Date(today);
        d.setDate(today.getDate() + i);
        dates.push({
          date: d.toDateString(),
          timestamp: d.toISOString(),
        });
      }
      return dates;
    });

    // Past orders table headers
    const pastOrderHeaders = [
      { text: "Order ID", value: "id" },
      { text: "Requested Date", value: "createdAt" },
      { text: "Collected Date", value: "collectedAt" },
      { text: "Volume", value: "actualVolume" },
      { text: "Status", value: "status" },
    ];

    // Fetch order details via API
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getOrder/${props.id}`);
        order.value = response.data;
        if (order.value.geotag) {
          const res = await axios.get(
            `${apiUrl}/getLatLng/${order.value.geotag}`
          );
          center.value = res.data;
        }
        if (order.value.signedOffSignature) {
          imageData.value = order.value.signedOffSignature;
        }
        plannedFor.value = order.value.scheduledDate;
        editOrderStatus.value = order.value.status;
        if (order.value.notes) {
          editOrderNotes.value = order.value.notes;
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    // Fetch past orders via API (sorted by collectedAt descending)
    const fetchPastOrders = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/getPastOrders?supplierId=${order.value.supplierId}`
        );
        pastOrders.value = response.data;
      } catch (error) {
        console.error("Error fetching past orders:", error);
      }
    };

    // Update order details via API
    const updateOrder = async () => {
      try {
        const payload = {
          id: props.id,
          status: editOrderStatus.value,
          actualVolume: editOrderActualVolume.value || null,
          notes: editOrderNotes.value,
        };
        await axios.post(`${apiUrl}/updateOrder`, payload);
        editOrder.value = false;
        await fetchOrderDetails();
      } catch (error) {
        console.error("Error updating order:", error);
      }
    };

    // When plannedFor changes, update the scheduled date via API
    watch(plannedFor, async (newVal) => {
      if (newVal && order.value && newVal !== order.value.scheduledDate) {
        try {
          const payload = {
            id: props.id,
            scheduledDate: newVal,
          };
          await axios.post(`${apiUrl}/updateOrderScheduledDate`, payload);
          await fetchOrderDetails();
        } catch (error) {
          console.error("Error updating scheduled date:", error);
        }
      }
    });

    // Fetch all data, then set loading to false
    const fetchAllData = async () => {
      await fetchOrderDetails();
      if (order.value && order.value.supplierId) {
        await fetchPastOrders();
      }
      loading.value = false;
    };

    fetchAllData();

    return {
      loading,
      order,
      center,
      pastOrders,
      pastOrderHeaders,
      dateList,
      imageData,
      editOrder,
      editOrderStatus,
      editOrderActualVolume,
      editOrderNotes,
      plannedFor,
      updateOrder,
    };
  },
  components: {
    SpinnerView,
    GoogleMap,
    Marker,
    Vue3EasyDataTable,
  },
};
</script>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 30px;
}
</style>
