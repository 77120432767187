<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <p class="is-size-6">{{ body }}</p>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-rounded is-danger" @click="confirm">
          Yes
        </button>
        <button class="button is-rounded is-primary" @click="cancel">No</button>
      </footer>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "DeleteRecordDialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const confirm = () => {
      emit("confirm");
    };
    const cancel = () => {
      emit("cancel");
    };
    return { confirm, cancel };
  },
};
</script>
  
  <style scoped>
/* You can add additional styles if needed */
</style>
  