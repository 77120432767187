<template>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="is-flex is-justify-content-space-between">
          <p class="modal-card-title">Schedule Collections</p>
        </div>
      </header>

      <section class="modal-card-body">
        <!-- If there is exactly one collection request, allow toggling between creating a new route or adding to an existing planned route -->
        <div class="mb-5" v-if="collectionRequests.length === 1">
          <button
            class="button is-success"
            @click="toggleInsertRoute"
            :disabled="!allRoutes.length"
          >
            {{ allowInsertRoute ? "Create new route" : "Add to planned route" }}
          </button>
        </div>

        <div v-if="!allowInsertRoute">
          <form>
            <div class="field">
              <div class="control">
                <input
                  class="input is-normal"
                  placeholder="Driver Name"
                  v-model="driverName"
                />
              </div>
            </div>

            <!-- Truck Number Options based on userSite -->
            <div
              class="field"
              v-if="userSite === 'fa669bd0-42f8-4edf-8847-9acd191759f1'"
            >
              <label class="label">Truck Number</label>
              <div class="select control">
                <select v-model="truckNumber">
                  <option value="5545">5545</option>
                  <option value="28890">28890</option>
                </select>
              </div>
            </div>
            <div
              class="field"
              v-else-if="userSite === 'a8b87580-1745-408a-a9fb-0d27e4f7ce2d'"
            >
              <label class="label">Truck Number</label>
              <div class="select control">
                <select v-model="truckNumber">
                  <option value="14927">14927</option>
                  <option value="22301">22301</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Route Type</label>
              <div class="select control">
                <select v-model="routeType">
                  <option value="UCO">UCO</option>
                  <option value="B100">B100</option>
                  <option value="B20">B20</option>
                  <option value="B5">B5</option>
                </select>
              </div>
            </div>

            <!-- Route Start Options based on userSite -->
            <div
              class="field"
              v-if="userSite === 'fa669bd0-42f8-4edf-8847-9acd191759f1'"
            >
              <label class="label">Route Start</label>
              <div class="select control">
                <select v-model="routeStart">
                  <option value="DXB_NF_SITE">Dubai Factory</option>
                  <option value="DXB_NF_ACCOM">Dubai Accomodation</option>
                </select>
              </div>
            </div>
            <div
              class="field"
              v-else-if="userSite === 'a8b87580-1745-408a-a9fb-0d27e4f7ce2d'"
            >
              <label class="label">Route Start</label>
              <div class="select control">
                <select v-model="routeStart">
                  <option value="AUH_NF_SITE">Abu Dhabi Factory</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Planned Date</label>
              <div class="select control">
                <select v-model="plannedDateIso">
                  <option
                    v-for="date in dateList"
                    :key="date.timestamp"
                    :value="date.timestamp"
                  >
                    {{ date.date }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>

        <div v-else>
          <!-- When allowInsertRoute is true, show list of planned routes -->
          <div class="field">
            <label class="label">Planned Routes</label>
            <div class="select control">
              <select v-model="selectedRouteId">
                <option
                  v-for="route in allRoutes"
                  :key="route.id"
                  :value="route.id"
                >
                  {{
                    route.nfRouteType +
                    " - " +
                    route.route_id +
                    " - " +
                    route.member_first_name +
                    " - " +
                    route.schduledDate +
                    " - " +
                    route.nfTruckNumber
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot">
        <button
          class="button is-success"
          :class="{ 'is-loading': isPending }"
          @click="handleClick"
        >
          {{ allowInsertRoute ? "Add" : "Create" }}
        </button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "ScheduleCollectionModal",
  props: {
    showForm: {
      type: Boolean,
      required: true,
    },
    collectionRequests: {
      type: Array,
      required: true,
    },
  },
  emits: ["close", "created"],
  setup(props, { emit }) {
    const store = useStore();
    const apiUrl = store.state.apiUrl;
    const isPending = ref(false);
    const driverName = ref("");
    const truckNumber = ref("");
    const routeType = ref("");
    const routeStart = ref("");
    const plannedDateIso = ref("");
    const allowInsertRoute = ref(false);
    const selectedRouteId = ref("");
    const allRoutes = ref([]);

    // Compute dateList (next 7 days)
    const dateList = computed(() => {
      const dates = [];
      const today = new Date();
      today.setHours(4, 0, 0, 0);
      for (let i = 0; i < 7; i++) {
        const d = new Date(today);
        d.setDate(today.getDate() + i);
        dates.push({
          date: d.toDateString(),
          timestamp: d.toISOString(),
        });
      }
      return dates;
    });

    // When collectionRequests has more than one item, create an addresses array.
    // Otherwise, use a single address.
    const addresses = computed(() => {
      if (props.collectionRequests.length > 1) {
        return props.collectionRequests.map((cr) => ({
          address: cr.geotag,
          alias: cr.locationName
            ? `${cr.supplierName} - ${cr.locationName}`
            : cr.supplierName,
          time: 30,
          addressStopType: "PICKUP",
          email: "string",
          weight: cr.estimatedVolume,
          isDepot: false,
          serviceTime: "string",
          refNo: cr.id,
        }));
      } else if (props.collectionRequests.length === 1) {
        const cr = props.collectionRequests[0];
        return {
          address: cr.geotag,
          alias: cr.locationName
            ? `${cr.supplierName} - ${cr.locationName}`
            : cr.supplierName,
          time: 30,
          addressStopType: "PICKUP",
          email: "string",
          weight: cr.estimatedVolume,
          isDepot: false,
          serviceTime: "string",
          refNo: cr.id,
        };
      } else {
        return null;
      }
    });

    const fetchPlannedRoutes = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/getPlannedRoutes/${store.state.currentSite}`
        );
        allRoutes.value = response.data;
      } catch (error) {
        console.error("Error fetching planned routes:", error);
      }
    };

    watchEffect(() => {
      fetchPlannedRoutes();
    });

    // Toggle between creating a new route and adding to an existing planned route.
    const toggleInsertRoute = () => {
      allowInsertRoute.value = !allowInsertRoute.value;
    };

    // Handle the create/add button click.
    const handleClick = async () => {
      isPending.value = true;
      try {
        if (allowInsertRoute.value) {
          // Insert the single address into an existing route.
          const url = `${apiUrl}/insertAddressesToRoute?route_id=${selectedRouteId.value}`;
          const response = await axios.post(url, addresses.value);
          // Assume a success response contains status 200 in content[1]
          if (response.data[1] === 200) {
            emit("created");
          }
        } else {
          // Create a new route using the provided details.
          const routePayload = {
            driverName: driverName.value,
            truckNumber: truckNumber.value,
            routeType: routeType.value,
            routeStart: routeStart.value,
            plannedDateISO: plannedDateIso.value,
            siteId: store.state.currentSite,
            addresses:
              addresses.value instanceof Array
                ? addresses.value
                : [addresses.value],
          };
          const response = await axios.post(
            `${apiUrl}/createRoute`,
            routePayload
          );
          // Assume a success response contains status 200 in content[1]
          if (response.data[1] === 200) {
            emit("created");
          }
        }
      } catch (error) {
        console.error("Error scheduling collections:", error);
      } finally {
        isPending.value = false;
      }
    };

    const closeModal = () => {
      emit("close");
    };

    return {
      isPending,
      driverName,
      truckNumber,
      routeType,
      routeStart,
      plannedDateIso,
      dateList,
      allowInsertRoute,
      toggleInsertRoute,
      allRoutes,
      selectedRouteId,
      handleClick,
      closeModal,
      // Expose userSite from store (if needed)
      userSite: computed(() => store.state.currentSite),
    };
  },
};
</script>

<style scoped>
/* Add any additional modal styles here */
</style>
