<template>
  <div>
    <!-- Top controls: Search, Date Picker, and Action Buttons -->
    <div class="container is-flex is-justify-content-space-between">
      <div>
        <h1 class="is-size-3 title">Active Orders</h1>
        <div class="container is-flex mb-5">
          <!-- Search controls -->
          <div class="field mr-5">
            <label class="label">Search Item</label>
            <div class="select">
              <select v-model="searchField">
                <option value="supplierName">Supplier Name</option>
                <option value="locationName">Location Name</option>
                <option value="createdBy">Created By</option>
                <option value="status">Status</option>
              </select>
            </div>
          </div>
          <div v-if="searchField !== 'status'" class="field mr-5">
            <label class="label">Search For</label>
            <input class="input" type="text" v-model="searchValue" />
          </div>
          <div v-else class="field mr-5">
            <label class="label">Search Item</label>
            <div class="select">
              <select v-model="searchValue">
                <option value="COMPLETED">COMPLETED</option>
                <option value="SCHEDULED">SCHEDULED</option>
                <option value="REQUESTED">REQUESTED</option>
                <option value="PLANNED">PLANNED</option>
              </select>
            </div>
          </div>
          <div class="field mt-5" v-if="searchValue">
            <div class="control">
              <button class="button is-light is-small" @click="clearFilter">
                Clear Filter
              </button>
            </div>
          </div>
          <div class="field">
            <label class="label">Requested Start Date</label>
            <div class="control">
              <VueDatePicker
                class="is-rounded"
                v-model="startDate"
                :enable-time-picker="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- Action Buttons -->
        <button
          v-if="allowSchedule"
          @click="showScheduleCollection = true"
          class="button is-rounded is-warning mr-5"
        >
          Schedule Collections
        </button>
        <button
          v-if="selectedRequests.length && !allScheduled && !allowSchedule"
          class="button is-rounded is-success mr-5"
          :class="{ 'is-loading': isAcceptLoading }"
          @click="handleAccept"
        >
          Accept Collections
        </button>
        <button
          class="button is-rounded is-primary"
          v-if="!showModal"
          @click="openModal"
        >
          Create Collection Request
        </button>
      </div>
    </div>

    <!-- Loading Indicator and Orders Table -->
    <div>
      <div v-if="isLoading" class="has-text-centered">
        <progress class="progress is-small is-primary" max="100">
          Loading...
        </progress>
      </div>
      <div v-else>
        <div
          v-if="selectedRequests.length"
          class="is-flex mb-5 is-justify-content-space-between"
        >
          <h1>
            No of selected requests: <b>{{ selectedRequests.length }}</b>
          </h1>
          <h1>
            Estimate total volume: <b>{{ totalVolume }} Liters</b>
          </h1>
        </div>
        <Vue3EasyDataTable
          :headers="headers"
          :items="orders"
          :search-field="searchField"
          :search-value="searchValue"
          table-class-name="customize-table"
          v-model:items-selected="selectedRequests"
          header-text-direction="center"
          body-text-direction="center"
          border-cell
          show-index
        >
          <template #item-supplierName="{ supplierName, id, supplierId }">
            <router-link
              :to="{
                name: 'OrderView',
                params: { id: id, supplierId: supplierId },
              }"
            >
              {{ supplierName }}
            </router-link>
          </template>
          <template #item-requestedDate="{ requestedDate }">
            {{ new Date(requestedDate).toDateString() }}
          </template>
          <template #item-status="{ status }">
            <span v-if="status === 'REQUESTED'" class="tag is-rounded">
              {{ status }}
            </span>
            <span
              v-else-if="status === 'PLANNED'"
              class="tag is-rounded"
              :class="{ 'is-warning': status === 'PLANNED' }"
            >
              {{ status }}
            </span>
            <span
              v-else-if="status === 'SCHEDULED'"
              class="tag is-rounded"
              :class="{ 'is-success': status === 'SCHEDULED' }"
            >
              {{ status }}
            </span>
            <span
              v-else-if="status === 'COMPLETED'"
              class="tag is-rounded"
              :class="{ 'is-dark': status === 'COMPLETED' }"
            >
              {{ status }}
            </span>
            <span
              v-else-if="status === 'FAILED'"
              class="tag is-rounded"
              :class="{ 'is-danger': status === 'FAILED' }"
            >
              {{ status }}
            </span>
          </template>
          <template #item-scheduledDate="{ scheduledDate }">
            <p v-if="scheduledDate">
              {{ new Date(scheduledDate).toDateString() }}
            </p>
          </template>
          <template
            #item-flags="{
              replaceContainer,
              highPriority,
              additionalContainer,
            }"
          >
            <img
              v-if="replaceContainer"
              class="mr-2"
              src="@/assets/icons/refresh.png"
              alt="Replace Container"
              style="height: 20px"
            />
            <img
              v-if="highPriority"
              class="mr-2"
              src="@/assets/icons/red-flag.png"
              alt="High Priority"
              style="height: 20px"
            />
            <img
              v-if="additionalContainer"
              src="@/assets/icons/plus.png"
              alt="Additional Container"
              style="height: 20px"
            />
          </template>
          <template
            #item-operations="{
              id,
              status,
              supplierName,
              locationName,
              supplierId,
            }"
          >
            <!-- Delete icon triggers the confirmDelete() method -->
            <img
              v-if="
                status !== 'SCHEDULED' &&
                status !== 'COMPLETED' &&
                status !== 'FAILED'
              "
              class="mr-2"
              src="@/assets/icons/delete.png"
              alt="Delete"
              style="height: 20px"
              @click="confirmDelete({ id, supplierName, locationName })"
            />
            <router-link
              :to="{
                name: 'OrderView',
                params: { id: id, supplierId: supplierId },
              }"
            >
              <img
                src="@/assets/icons/pencil.png"
                alt="Edit"
                style="height: 20px"
              />
            </router-link>
          </template>
        </Vue3EasyDataTable>
      </div>
    </div>

    <!-- Collection Request Modal -->
    <div v-if="showModal">
      <CollecionRequestModal
        :showForm="showModal"
        @close="handleModalClose"
        @created="handleCollectionCreated"
      />
    </div>

    <!-- Delete Confirmation Dialog -->
    <div v-if="showDeleteDialog">
      <DeleteRecordDialog
        :title="deleteTitle"
        :body="deleteBody"
        :showModal="showDeleteDialog"
        @confirm="deleteConfirmed"
        @cancel="cancelDelete"
      />
    </div>

    <!-- Schedule Collection Modal -->
    <div v-if="showScheduleCollection">
      <ScheduleCollectionModal
        :showForm="showScheduleCollection"
        @close="showScheduleCollection = false"
        @created="handleCollectionsScheduled"
        :collectionRequests="selectedRequests"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from "vue";
import axios from "axios";
import CollecionRequestModal from "@/components/CollecionRequestModal.vue";
import ScheduleCollectionModal from "@/components/ScheduleCollectionModal.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import DeleteRecordDialog from "@/components/DeleteRecordDialog.vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const apiUrl = store.state.apiUrl;
    const orders = ref([]);
    const selectedRequests = ref([]);
    const isLoading = ref(false);
    const isAcceptLoading = ref(false);
    const startDate = ref(new Date());
    const searchField = ref("");
    const searchValue = ref("");
    const totalVolume = ref(0);
    const showModal = ref(false);
    const showScheduleCollection = ref(false);

    // Delete confirmation dialog reactive variables
    const showDeleteDialog = ref(false);
    const orderToDelete = ref(null);
    const deleteTitle = ref("");
    const deleteBody = ref("");

    const headers = [
      { text: "Supplier Name", value: "supplierName", sortable: true },
      { text: "Supplier Location", value: "locationName", sortable: true },
      { text: "Created By", value: "createdBy", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Order Type", value: "orderType", sortable: true },
      { text: "Requested Date", value: "requestedDate", sortable: true },
      { text: "Planned Date", value: "scheduledDate", sortable: true },
      { text: "Flags", value: "flags" },
      { text: "Operations", value: "operations" },
    ];

    const fetchOrders = async () => {
      isLoading.value = true;
      try {
        const payload = {
          start_date: startDate.value.toISOString(),
          site_id: store.state.currentSite,
        };
        const response = await axios.post(`${apiUrl}/getOrders`, payload);
        orders.value = response.data;
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        isLoading.value = false;
      }
    };

    watchEffect(() => {
      fetchOrders();
    });

    const clearFilter = () => {
      searchValue.value = "";
    };

    // Open the delete confirmation dialog with order details.
    const confirmDelete = (order) => {
      orderToDelete.value = order;
      deleteTitle.value = `Delete Order: ${order.id}`;
      deleteBody.value =
        `Are you sure you want to delete order ${order.id} from supplier "${order.supplierName}"` +
        (order.locationName ? ` at location "${order.locationName}"?` : "?");
      showDeleteDialog.value = true;
    };

    // Called when the user confirms deletion.
    const deleteConfirmed = async () => {
      if (!orderToDelete.value) return;
      try {
        await axios.delete(`${apiUrl}/orders/${orderToDelete.value.id}`);
        fetchOrders();
      } catch (error) {
        console.error("Error deleting record:", error);
      } finally {
        showDeleteDialog.value = false;
        orderToDelete.value = null;
      }
    };

    const cancelDelete = () => {
      showDeleteDialog.value = false;
      orderToDelete.value = null;
    };

    const handleAccept = async () => {
      isAcceptLoading.value = true;
      try {
        const ordersPayload = selectedRequests.value.map((order) => ({
          id: order.id,
          requestedDate: order.requestedDate,
        }));
        await axios.post(`${apiUrl}/acceptOrders`, { orders: ordersPayload });
        await fetchOrders();
        selectedRequests.value = [];
      } catch (error) {
        console.error("Error accepting orders:", error);
      } finally {
        isAcceptLoading.value = false;
      }
    };

    const allowSchedule = computed(() => {
      return (
        selectedRequests.value.length > 0 &&
        selectedRequests.value.every((order) => order.status === "PLANNED")
      );
    });

    const allScheduled = computed(() => {
      return (
        selectedRequests.value.length > 0 &&
        selectedRequests.value.every((order) => order.status === "SCHEDULED")
      );
    });

    watchEffect(() => {
      totalVolume.value = selectedRequests.value.reduce(
        (sum, order) => sum + (order.estimatedVolume || 0),
        0
      );
    });

    const openModal = () => {
      showModal.value = true;
    };

    const handleModalClose = () => {
      showModal.value = false;
    };

    const handleCollectionCreated = () => {
      fetchOrders();
      showModal.value = false;
    };

    const handleCollectionsScheduled = () => {
      fetchOrders();
      showScheduleCollection.value = false;
      selectedRequests.value = [];
    };

    store.commit("setCurrentRoute", "Orders");

    return {
      orders,
      handleCollectionsScheduled,
      selectedRequests,
      isLoading,
      isAcceptLoading,
      startDate,
      searchField,
      searchValue,
      totalVolume,
      showModal,
      openModal,
      handleModalClose,
      handleCollectionCreated,
      fetchOrders,
      clearFilter,
      handleAccept,
      allowSchedule,
      showScheduleCollection,
      allScheduled,
      // Delete dialog bindings
      showDeleteDialog,
      deleteTitle,
      deleteBody,
      confirmDelete,
      deleteConfirmed,
      cancelDelete,
      headers,
    };
  },
  components: {
    CollecionRequestModal,
    Vue3EasyDataTable,
    DeleteRecordDialog,
    ScheduleCollectionModal,
    VueDatePicker,
  },
};
</script>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 30px;
}
</style>
