<template>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">New Collection Request</p>
      </header>
      <section class="modal-card-body">
        <form>
          <!-- Supplier Selection -->
          <div class="field">
            <label class="label">Supplier</label>
            <div class="select control">
              <select v-model="selectedSupplierId" required>
                <option
                  v-for="supplier in suppliers"
                  :key="supplier.id"
                  :value="supplier.id"
                >
                  {{ supplier.suppliername }}
                </option>
              </select>
            </div>
          </div>
          <!-- Show additional fields after supplier selection -->
          <div v-if="selectedSupplier">
            <!-- Location Selection (if available) -->
            <div v-if="locations && locations.length" class="field">
              <label class="label">Location</label>
              <div class="select control">
                <select v-model="selectedLocationId" required>
                  <option
                    v-for="location in locations"
                    :key="location.locationid"
                    :value="location.locationid"
                  >
                    {{ location.locationname }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Request Date -->
            <div class="field">
              <label class="label">Request Date</label>
              <div class="select control">
                <select v-model="requestDate" required>
                  <option
                    v-for="date in dateList"
                    :key="date.timestamp"
                    :value="date.timestamp"
                  >
                    {{ date.date }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Additional Requirements -->
            <div class="field mb-5">
              <label class="label">Additional Requirements</label>
              <div class="control">
                <label class="checkbox ml-5">
                  <input type="checkbox" v-model="replaceContainer" />
                  Replace Container
                </label>
                <label class="checkbox ml-5">
                  <input type="checkbox" v-model="highPriority" />
                  High Priority
                </label>
                <label class="checkbox ml-5">
                  <input type="checkbox" v-model="additionalContainer" />
                  Additional Container
                </label>
              </div>
            </div>
            <!-- Estimated Volume (display only) -->
            <div class="field">
              <label class="label">
                Estimated Volume
                <input
                  type="text"
                  class="input"
                  :value="estimatedVolume"
                  disabled
                />
              </label>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-success"
          :class="{ 'is-loading': isPending }"
          @click="handleClick"
        >
          Create
        </button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showForm"],
  emits: ["close", "created"],
  setup(props, { emit }) {
    const store = useStore();
    const apiUrl = store.state.apiUrl;
    const siteId = store.state.currentSite;

    // Reactive variables for form data
    const suppliers = ref([]);
    const selectedSupplierId = ref(null);
    const selectedSupplier = ref(null);
    const locations = ref([]);
    const selectedLocationId = ref(null);
    const requestDate = ref(null);
    const dateList = ref([]);
    const replaceContainer = ref(false);
    const highPriority = ref(false);
    const additionalContainer = ref(false);
    const estimatedVolume = ref(null);
    const isPending = ref(false);
    const geotag = ref(null);

    // Fetch suppliers via API
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/suppliers/${siteId}`);
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    onMounted(() => {
      fetchSuppliers();
    });

    // When supplier selection changes, update selectedSupplier and locations
    watch(selectedSupplierId, () => {
      selectedSupplier.value = suppliers.value.find(
        (supplier) => supplier.id === selectedSupplierId.value
      );
      geotag.value = selectedSupplier.value.geotag;
      selectedLocationId.value = null;
      locations.value = selectedSupplier.value
        ? selectedSupplier.value.locations
        : [];
      // If no locations are available, calculate estimated volume from supplier containers
      if (
        selectedSupplier.value &&
        (!locations.value || locations.value.length === 0)
      ) {
        if (selectedSupplier.value.containers) {
          let vol = 0;
          selectedSupplier.value.containers.forEach((container) => {
            vol += container.maxVolume * container.quantity * 0.8;
          });
          estimatedVolume.value = vol;
        } else {
          estimatedVolume.value = 150;
        }
      }
      // Fetch available dates (pass locationId=0 if none selected)
      if (selectedSupplierId.value) {
        const effectiveLocationId = selectedLocationId.value || 0;
        fetchDates(selectedSupplierId.value, effectiveLocationId);
      } else {
        dateList.value = [];
      }
    });

    // When location selection changes, update estimated volume and fetch dates
    watch(selectedLocationId, () => {
      if (locations.value && selectedLocationId.value) {
        const selectedLoc = locations.value.find(
          (loc) => loc.locationid === selectedLocationId.value
        );
        geotag.value = selectedLoc.areaname;
        if (selectedSupplier.value && selectedSupplier.value.containers) {
          let vol = 0;
          selectedSupplier.value.containers.forEach((container) => {
            if (container.locationId == selectedLocationId.value) {
              vol += container.maxVolume * container.quantity * 0.8;
            }
          });
          estimatedVolume.value = vol;
        }
        fetchDates(selectedSupplierId.value, selectedLocationId.value);
      }
    });

    // Function to fetch available dates via API
    const fetchDates = async (supplierId, locationId) => {
      try {
        const response = await axios.get(
          `${apiUrl}/getSupplierLocationPlannerData/${supplierId}/${locationId}`
        );
        // Assume the API returns an array of ISO date strings
        dateList.value = response.data.map((isoDate) => {
          const d = new Date(isoDate);
          return {
            date: d.toDateString(),
            timestamp: d.toISOString(), // using ISO string as value
          };
        });
      } catch (err) {
        console.error("Error fetching dates:", err);
      }
    };

    // Close modal by emitting event
    const closeModal = () => {
      emit("close");
    };

    // Handle the create button click: post the new collection request via API
    const handleClick = async () => {
      isPending.value = true;
      try {
        const collectionRequest = {
          supplierName: selectedSupplier.value.suppliername,
          supplierId: selectedSupplierId.value,
          locationId: selectedLocationId.value ? selectedLocationId.value : "0",
          locationName: selectedLocationId.value
            ? (
                locations.value.find(
                  (loc) => loc.locationid === selectedLocationId.value
                ) || {}
              ).locationname
            : null,
          createdAt: new Date().toISOString(),
          orderType: "COLLECTION",
          createdBy: store.state.user.displayName,
          siteId: selectedSupplier.value.siteid,
          countryid: selectedSupplier.value.countryid,
          geotag: geotag.value || null,
          requestedBy: "Neutral Fuels",
          status: "REQUESTED",
          modifiedAt: new Date().toISOString(),
          requestedDate: requestDate.value, // expect an ISO string from the select
          scheduledDate: null,
          replaceContainer: replaceContainer.value,
          highPriority: highPriority.value,
          additionalContainer: additionalContainer.value,
          estimatedVolume: estimatedVolume.value,
        };
        console.log(collectionRequest);
        await axios.post(
          `${apiUrl}/createCollectionRequest`,
          collectionRequest
        );
        // Emit the "created" event so that the parent component can refresh orders and close the modal.
        emit("created");
      } catch (error) {
        console.error("Error creating collection request:", error);
      } finally {
        isPending.value = false;
      }
    };

    return {
      suppliers,
      selectedSupplierId,
      selectedSupplier,
      locations,
      selectedLocationId,
      requestDate,
      dateList,
      replaceContainer,
      highPriority,
      additionalContainer,
      estimatedVolume,
      isPending,
      closeModal,
      handleClick,
    };
  },
};
</script>

<style scoped>
/* Add your modal styling here if needed */
</style>
